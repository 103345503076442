<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <main>
      <div class="header-div">
        <div class="header-left">
          <VasionButton
            id="back-button"
            classProp="text-transparent"
            :isFullWidth="false"
            @vasionButtonClicked="cancel()">
              <div class="align-text-and-icon">
                <VasionChevronLeft />
                <span class="back-button-text">Back</span>
              </div>
          </VasionButton>
        </div>
        <div class="header-right">
          <span class="filter-title">Filter By</span>
          <VasionButton
            id="highConfidence"
            name="btnHighConfidence"
            :classProp="selectedFilter === 'high' ? 'text-transparent-selected' : 'text-transparent'"
            :ripple="false"
            @vasionButtonClicked="filterCards('high')"
          >
            High Confidence
          </VasionButton>
          <VasionButton
            id="medConfidence"
            name="btnMedConfidence"
            :classProp="selectedFilter === 'medium' ? 'text-transparent-selected' : 'text-transparent'"
            :ripple="false"
            @vasionButtonClicked="filterCards('medium')"
          >
            Med Confidence
          </VasionButton>
          <VasionButton
            id="lowConfidence"
            name="btnLowConfidence"
            :classProp="selectedFilter === 'low' ? 'text-transparent-selected' : 'text-transparent'"
            :ripple="false"
          @vasionButtonClicked="filterCards('low')"
          >
            Low Confidence
          </VasionButton>
          <VasionButton
            id="clear"
            name="btnClear"
            title="Clear"
            classProp="text-transparent"
            :ripple="false"
            class="clear-button"
            @vasionButtonClicked="filterCards('none')"
          >
            Clear
          </VasionButton>
          <VasionButton
            id="btnSave"
            name="btnSave"
            title="Save"
            classProp="primary"
            :isDisabled="!isDirty || invalidFields.length > 0"
            @vasionButtonClicked="save()"
          >
            Save
          </VasionButton>
        </div>
      </div>
      <div class="second-header-div">
        <div class="second-header-left">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedObject"
            type="plain-list"
            :width="'370'"
            displayName="Name"
            valueName="ObjectID"
            title="OBJECT"
            :dataArray="allObjects"
            :checkCanUpdate="doDirtyObjectCheck"
          >
            {{ slotItem.item.Name }}
          </VasionDropList>
        </div>
        <div class="second-header-right">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedFieldsToHide[selectedObject.ObjectID]"
            ref="objectFieldsToHide"
            type="check-list"
            :width="'100%'"
            displayName="DisplayName"
            valueName="FieldID"
            title="OBJECT FIELDS TO HIDE"
            :dataArray="allFieldsInObject"
          >
            {{ slotItem.item.DisplayName }}
          </VasionDropList>
        </div>
      </div>
      <div id="body-area">
        <div class="document-display" v-if="isPreviewOpen">
          <div class="preview-header">
            <div class="preview-button">
              <VasionButton
                id="back-button"
                classProp="text-transparent"
                :isFullWidth="false"
                @vasionButtonClicked="closePreview()">
                <div class="align-text-and-icon">
                  <VasionChevronLeft />
                  <span class="preview-button-text">Preview</span>
                </div>
              </VasionButton>
            </div>
            <div class="zoom-tool">
              <VasionZoomTool />
            </div>
          </div>
          <div id="document-image-div" class="document-image">
            <DocumentImage
              ref="mainImage"
              :documentID="documentID"
              :key="documentImageKey"
              :pageNumber="currentPageNumber"
              :width="viewerWidth"
              :height="viewerHeight"
              :fileDetail="fileDetails"
              :largeDefaultLoadSize="true"
              :includeProcessedAIPZones="true"
              :context="'AnnotationsAndAIPZones'"
              :aipZones="aipZones"
              :disableMoveAndSaveAnnotations="true"
              :selectedAIP="selectedAIP"
              @backgroundImageLoaded="backgroundImageLoaded"
            />
          </div>
          <div class="preview-footer">
            <VasionPager
              :currentPageNumber="currentPageNumber"
              :showPageSize="false"
              :totalPages="pageCount"
              @pageChanged="goToPage"
            />
          </div>
        </div>
        <div class="field-validation-side" :class="{'full-width' : !isPreviewOpen}">
          <VasionFieldValidationCard
            v-for="(document, index) in fieldValidationData.Documents"
            :key="index"
            :cardData="document"
            :filter="selectedFilter"
            :hiddenColumnIDs="hiddenColumnIDs"
            :selectedObjectID="selectedObject.ObjectID"
            @cardClicked="cardClicked"
            @fieldClicked="fieldClicked"
            @markClean="markClean"
            @markDirty="markDirty"
            @markFieldAsNotValid="markFieldAsNotValid"
            @markFieldAsValid="markFieldAsValid"
            @updateFieldValues="updateFieldValues"
          />
        </div>
      </div>
    </main>
    <md-dialog :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
    <VasionAsynchronousConfirmDialog ref="confirm" confirmText="Yes" cancelText="No" />
  </div>
</template>

<script>
import VasionAsynchronousConfirmDialog from '@/components/shared/VasionAsynchronousConfirmDialog.vue'
import VasionFieldValidationCard from '@/components/shared/VasionFieldValidationCard.vue';
import Loading from 'vue-loading-overlay';
import { testIsGuid } from '@/store/helperModules/common.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import DocumentImage from '@/components/document/DocumentImage.vue';
import VasionZoomTool from '@/components/shared/VasionZoomTool.vue';
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { isArray } from 'lodash';
import { isObject } from 'lodash';

export default {
  name: 'TheFieldValidationAdvancedView',
  components: {
    Loading,
    VasionAsynchronousConfirmDialog,
    DocumentImage,
    VasionFieldValidationCard,
    VasionZoomTool
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function() {
    return {
      aipZones: {
        values: [],
        selectedDocumentIndex: -1,
        aipZoneDocumentRanges: {},
      },
      currentPageNumber: 1,
      document: {},
      documentImageKey: 0,
      fieldValidationData: {},
      fieldValues: [],
      fileDetails: {},
      folderView: null,
      invalidFields: [],
      isDirty: false,
      isLoading: true,
      isPreviewOpen: true,
      loaderBackgroundColor,
      loaderColor,
      localBackRoute: '',
      selectedFieldsToHide: [],
      selectedObject: { ObjectID: 0 },
      selectedFilter: 'none',
      selectedAIP: '',
      showLeaveDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarText: '',
      snackbarTitle: '',
      viewerHeight: 771,
      viewerWidth: 400,
    }
  },
  computed: {
    allFieldsInObject() {
      let fieldsList = []
      if (this?.fieldValidationData?.Objects && this?.selectedObject?.ListID >= 0) {
        for (let i = 0; i < this.fieldValidationData.Objects[this.selectedObject.ListID].Fields.length; i++){
          fieldsList.push({
            DisplayName: this.fieldValidationData.Objects[this.selectedObject.ListID].Fields[i].DisplayName,
            FieldID: this.fieldValidationData.Objects[this.selectedObject.ListID].Fields[i].FieldID,
            ListID: i,
            FieldName: this.fieldValidationData.Objects[this.selectedObject.ListID].Fields[i].FieldName,
          })
        }
      }
      return fieldsList
    },
    allObjects() {
      let objectsList = []
      if (this?.fieldValidationData?.Objects) {
        for (let i = 0; i < this.fieldValidationData.Objects.length; i++) {
          objectsList.push({
            Name: this.fieldValidationData.Objects[i].Name,
            ObjectID: this.fieldValidationData.Objects[i].ID,
            ListID: i,
          })
        }
      }
      return objectsList
    },
    custViewID() { return this.$attrs.custViewID },
    documentID() { return this.$attrs.documentID },
    documentIDs() { return this.$attrs.documentIDs },
    folderID() { return this.$attrs.folderID },
    hiddenColumnIDs() { return (this.selectedFieldsToHide[this.selectedObject.ObjectID]) ? this.selectedFieldsToHide[this.selectedObject.ObjectID].map(element => element.FieldName) : [] },
    lineItemChanges() { return this.$store.state.document.lineItemChanges },
    lineItemsFields() { return this.$store.state.document.lineItemsFields },
    pageCount() { return this.document?.FileDetail?.PageCount },
    previousPathIsDocViewer() { return this.$attrs.fromDocViewer },
    selectedLineItemField() { return this.$store.state.document.selectedLineItemField },
  },
  watch: {
    lineItemsFields: {
      handler: function () {
        const newFields = this.extractLineItemsFields()
        const mergedAipZones = this.mergeAndFilterUnique(this.aipZones.values, newFields)

        const { newAipZones, ranges } = this.organizeAipZonesByDocument(mergedAipZones, this.fieldValidationData.Documents)

        this.aipZones.values = newAipZones
        this.aipZones.aipZoneDocumentRanges = ranges
      },
      deep: true,
    },
    selectedLineItemField: {
      handler: async function () {
        if (!this.selectedLineItemField) return
        
        const isSameDocument = this.document.DocumentId === this.selectedLineItemField.documentId
        if (!isSameDocument) {
          this.loadCurrentDocument(this.selectedLineItemField.documentId)
          this.aipZones.selectedDocumentIndex = this.documentIDs.indexOf(this.selectedLineItemField.documentId)
        }

        const zoneX = this.selectedLineItemField.Confidence.ZoneX
        const zoneY = this.selectedLineItemField.Confidence.ZoneY

        const prevAIP = this.selectedAIP
        this.selectedAIP = !zoneX || !zoneY ? '' : this.generateUniqueID(zoneX, zoneY)

        if ((!isSameDocument || prevAIP !== this.selectedAIP) && this.selectedLineItemField.Confidence.PageNumber) {
          await this.reloadDocument(this.selectedLineItemField.documentId, this.selectedLineItemField.Confidence.PageNumber)
        }

        this.$store.dispatch('document/setSelectedLineItemField', null)
      },
      deep: true,
    },
    selectedObject: function () {
      this.isDirty = false
      this.invalidFields = []

      const firstDocumentForObject = this.fieldValidationData.Documents.find(d => d.FormId == this.selectedObject.ObjectID)
      this.$store.dispatch('document/setCardClicked', firstDocumentForObject)
      this.cardClicked(firstDocumentForObject)
    },
  },
  async mounted() {
    this.$root.confirm = this.$refs.confirm;
  },
  async created() {
    this.isLoading = true
    this.localBackRoute = !this.$store.state.document.backRoute ? '/' : this.$store.state.document.backRoute

    await this.refreshFieldValidationData()

    for (let i = 0; i < this.fieldValidationData.Objects.length; i++) {
      if (this.fieldValidationData.Documents[0].FormId === this.fieldValidationData.Objects[i].ID){
        this.selectedObject = {
          Name: this.fieldValidationData.Objects[i].Name,
          ObjectID: this.fieldValidationData.Objects[i].ID,
          ListID: 0,
        }
      }

      this.selectedFieldsToHide[this.fieldValidationData.Objects[i].ID] = []
      if (this.folderView?.length >= 0) {
        this.fieldValidationData.Objects[i].Fields.forEach(f => {
          const showField = this.folderView.find(sf => sf.fieldName === f.FieldName)
          if (showField === undefined) {
            this.selectedFieldsToHide[this.fieldValidationData.Objects[i].ID].push({
              DisplayName: f.DisplayName,
              FieldName: f.FieldName,
              FieldID: f.FieldID
            })
          }
        })
      }
    }
    let rangeTally = 0
    for (let i = 0; i < this.fieldValidationData.Documents.length; i++) {
      this.aipZones.aipZoneDocumentRanges[i] = []
      if (!this.fieldValidationData.Documents[i].IndexFormFields)
        continue
      this.fieldValidationData.Documents[i].IndexFormFields.Values.forEach((f) => {
        let color = {}
        switch(getMeanConfidenceColor(f.MeanConfidence)){
          case ("LawnGreen"):
            color = {
              RECT_COLOR_R: "42",
              RECT_COLOR_G: "200",
              RECT_COLOR_B: "20",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
          case ("Yellow"):
            color = {
              RECT_COLOR_R: "254",
              RECT_COLOR_G: "172",
              RECT_COLOR_B: "5",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
          case ("Red"):
            color = {
              RECT_COLOR_R: "254",
              RECT_COLOR_G: "20",
              RECT_COLOR_B: "20",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
          default:
            color = {
              RECT_COLOR_R: "4",
              RECT_COLOR_G: "20",
              RECT_COLOR_B: "200",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
        }
        this.aipZones.values.push({
          fill: color,
          DocumentId: this.fieldValidationData.Documents[i].DocumentId,
          ZoneHeight: f.ZoneHeight,
          ZoneWidth: f.ZoneWidth,
          ZoneX: f.ZoneX,
          ZoneY: f.ZoneY,
          Page: f.PageNumber,
          UniqueID: this.generateUniqueID(f.ZoneX, f.ZoneY),
        })
        this.aipZones.aipZoneDocumentRanges[i].push(rangeTally)
        rangeTally += 1
      })
    }
    this.fieldValues = new Array(this.fieldValidationData.Documents.length)

    this.isLoading = false
  },
  methods: {
    async backgroundImageLoaded(width){ 
      await this.$store.dispatch('document/resetZoomValue')
      const zoomWidth = document.getElementById('document-image-div').offsetWidth / (width + 1550)
      this.$store.dispatch('document/setZoomValue', zoomWidth)
    },
    async cardClicked(data) {
      if (data === null) return
      this.isPreviewOpen = true
      this.loadCurrentDocument(data.DocumentId)
      this.aipZones.selectedDocumentIndex = this.documentIDs.indexOf(data.DocumentId)
      await this.reloadDocument(data.DocumentId, 1)
      this.documentImageKey++
    },
    async cancel() {
      if (this.previousPathIsDocViewer) {
        await this.$router.go(-1)
        return
      }
      await this.$store.dispatch('document/setBackRoute', '/')
      this.$router.push({ path: this.localBackRoute })
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async closePreview() {
      this.isPreviewOpen = false
      await this.$store.dispatch('document/setCardClicked', null)
    },
    async doDirtyObjectCheck() {
      if (this.isDirty) {
        this.$refs.objectFieldsToHide.open = false
        let res = await this.$root.confirm.open({
          message: 'Are you sure you want to change the view? Changes have not been saved to the current object.',
        })
        return res
      }
      return true
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    extractLineItemsFields() {
      const lineItemsFields = []
      for (const [index, lineItem] of Object.entries(this.lineItemsFields)) {
        let documentId = index
        lineItem.forEach((table) => {
          table.Rows.forEach((row) => {
            row.Columns.forEach((column) => {
              if (column.Confidence?.ZoneHeight && column.Confidence?.ZoneWidth && column.Confidence?.ZoneX && column.Confidence?.ZoneY) {
                lineItemsFields.push({
                  fill: this.getConfidenceArrayColor(column.Confidence.MeanConfidence),
                  DocumentId: documentId,
                  Value: column.Value,
                  ZoneHeight: column.Confidence.ZoneHeight,
                  ZoneWidth: column.Confidence.ZoneWidth,
                  ZoneX: column.Confidence.ZoneX,
                  ZoneY: column.Confidence.ZoneY,
                  Page: column.Confidence.PageNumber,
                  UniqueID: this.generateUniqueID(column.Confidence.ZoneX, column.Confidence.ZoneY),
                })
              }
            })
          })
        })
      }
      return lineItemsFields
    },
    async fieldClicked(data) {
      let sameDocument = true
      if (this.document.DocumentId !== data.card.DocumentId) {
        sameDocument = false
        this.loadCurrentDocument(data.card.DocumentId)
        this.aipZones.selectedDocumentIndex = this.documentIDs.indexOf(data.card.DocumentId)
      }
      const origColumn = data.card.IndexFormFields.Values.find(c => c.FieldName === data.clickedField.value)
      let zoneX = origColumn.ZoneX
      let zoneY = origColumn.ZoneY

      const prevAIP = this.selectedAIP
      this.selectedAIP = !zoneX || !zoneY ? '' : this.generateUniqueID(zoneX, zoneY)
      if ((!sameDocument || prevAIP != this.selectedAIP) && data.clickedField.pageNumber) {
        await this.reloadDocument(data.card.DocumentId, data.clickedField.pageNumber)
      }
    },
    async filterCards(filter) {
      this.selectedFilter = filter
    },
    generateUniqueID(zoneX, zoneY) {
      return "AIP Zone" + zoneX + zoneY
    },
    getConfidenceArrayColor(meanConfidence) {
      let color = {}
      switch(getMeanConfidenceColor(meanConfidence)){
        case ("LawnGreen"):
          color = {
            RECT_COLOR_R: "42",
            RECT_COLOR_G: "200",
            RECT_COLOR_B: "20",
            RECT_COLOR_A: "100",
            disableSave: "True",
          }
          break
        case ("Yellow"):
          color = {
            RECT_COLOR_R: "254",
            RECT_COLOR_G: "172",
            RECT_COLOR_B: "5",
            RECT_COLOR_A: "100",
            disableSave: "True",
          }
          break
        case ("Red"):
          color = {
            RECT_COLOR_R: "254",
            RECT_COLOR_G: "20",
            RECT_COLOR_B: "20",
            RECT_COLOR_A: "100",
            disableSave: "True",
          }
          break
        default:
          color = {
            RECT_COLOR_R: "4",
            RECT_COLOR_G: "20",
            RECT_COLOR_B: "200",
            RECT_COLOR_A: "100",
            disableSave: "True",
          }
          break
      }
      return color
    },
    async getDocumentDetails(documentId) {
      if (await testIsGuid(documentId)) {
        const canPerformWFAction = await this.$store.dispatch('workflow/canPerformWFAction', documentId)
        if (canPerformWFAction === 'True') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'workspace')
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
        } else if (canPerformWFAction === 'False') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'storage')
        }
        return this.$store.dispatch('document/getDocumentDetailsByGuid', documentId)
      } else if (this.$route.params.version) {
        return this.$store.dispatch('document/getDocumentDetailsWithVersion', { documentID: documentId, version: this.$route.params.version })
      } else {
        return this.$store.dispatch('document/getDocumentDetails', { documentID: documentId, workflowStepID: this.$route.params.workflowStepId })
      }
    },
    goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.pageCount) {
        this.currentPageNumber = pageNumber
        this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      }
    },    
    async loadCurrentDocument(documentId) {
        const documentDetails = await this.$store.dispatch('document/getDocumentDetails', { documentID: documentId })
        this.fileDetails = documentDetails?.FileDetail
        this.documentName = documentDetails?.DisplayName
        this.totalPages = documentDetails?.FileDetail?.PageCount
        this.isDocumentLoaded = true
      },
    markClean() {
      this.isDirty = false
    },
    markDirty() {
      this.isDirty = true
    },
    markFieldAsValid(data) {
      // Remove the following line in the final integration
      console.log("markFieldAsValid data", data);
    },
    markFieldAsNotValid(data) {
      // Remove the following line in the final integration
      console.log("markFieldAsNotValid data", data);
    },
    mergeAndFilterUnique(array1, array2) {
      const mergedArray = [...array1, ...array2]
      return mergedArray.filter((obj, index, self) => 
        index === self.findIndex((t) => t.UniqueID === obj.UniqueID)
      )
    },
    organizeAipZonesByDocument(aipZones, documents) {
      let rangeTally = 0
      const ranges = []
      const newAipZones = []

      documents.forEach((doc, i) => {
        ranges[i] = []
        aipZones.forEach(zone => {
          if (Number(zone.DocumentId) === Number(doc.DocumentId)) {
            newAipZones.push(zone)
            ranges[i].push(rangeTally)
            rangeTally += 1
          }
        })
      })

      return { newAipZones, ranges }
    },
    async refreshFieldValidationData() {
      let payload = {
        DocumentIdString: this.documentIDs.toString(),
        viewerWidth: this.viewerWidth,
        viewerHeight: this.viewerHeight,
      }
      this.fieldValidationData = await this.$store.dispatch('document/getFieldValidationData', payload)

      if (this.selectedObject === null) {
        for (let i = 0; i < this.fieldValidationData.Objects.length; i++) {
          if (this.fieldValidationData.Documents[0].FormId === this.fieldValidationData.Objects[i].ID){
            this.selectedObject = {
              Name: this.fieldValidationData.Objects[i].Name,
              ObjectID: this.fieldValidationData.Objects[i].ID,
              ListID: 0,
            }
          }
        }
      }

      if (this.custViewID !== undefined && this.folderView === null) {
        const folderViewDetails = await this.$store.dispatch('admin/getCustomFolderViewDetailsForID', this.custViewID)
        this.folderView = folderViewDetails?.fields
      }
      else if (this.folderID !== undefined && this.folderView === null) {
        const folderViewDetails = await this.$store.dispatch('admin/getCustomFolderViewDetailsForFolder', this.folderID)
        this.folderView = folderViewDetails?.fields
      }
    },
    async reloadDocument(documentID, pageNumber) {
      this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      this.document = {}
      let data = await this.getDocumentDetails(documentID)

      if (!data || !data.DocumentId) {
        this.errorLoadingDocument = true
        return
      }

      this.errorLoadingDocument = false
      this.document = data

      this.$store.dispatch('document/getDocumentVersions', this.document.DocumentId)
      this.$store.dispatch('document/getDocumentPriority', this.document.DocumentId)

      this.$store.dispatch('document/setPendingSignatureAnnotationIDsForCurrentUser', this.document.pendingSignatureAnnotationIDsForCurrentUser)
      this.$store.dispatch('document/setPendingSignatureDocumentZones', this.document.pendingSignatureDocumentZones)

      if (this.$route.params.startingPage) {
        this.currentPageNumber = parseInt(this.$route.params.startingPage, 10)
      } else {
        this.currentPageNumber = pageNumber
      }
      this.$store.dispatch('document/addOneToRefreshKey')
    },
    async save() {
      this.isLoading = true
      
      const payload = this.documentIDs.map((id, index) => {
        if (this.fieldValues[index] !== undefined) {
          return {
            DocumentId: id,
            FormId: this.selectedObject.ObjectID,
            FieldValues: {
              Values: this.fieldValues[index].map(f => {
                let value = f.value
                if (isArray(f.value)) {
                  if (isObject(f.value[0])) {
                    value = f.value.map((x) => {return x.name}).join(';')
                  }
                  else {
                    value = f.value.join(';')
                  }
                }
                return {
                  Key: f.name,
                  Value: value,
                }
              })
            }
          }
        }
      }).filter(i => i !== undefined)
      const result = await this.$store.dispatch('document/saveFieldValidationData', payload)
      if (result) {
        this.isDirty = false
        this.invalidFields = []

        await this.refreshFieldValidationData()
        this.snackbarTitle = 'SUCCESS'
        this.snackbarText = 'Document fields saved successfully!'
        this.showSnackbar = true
        this.snackbarImage = true
        await this.$amplitude.trackEvent('Document Saved', {'docSaveViewType': 'Field Validation', })
      } else {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = 'There was an error saving the database fields'
        this.showSnackbar = true
        this.snackbarImage = false
      }

      if (this.lineItemChanges.length === 0) {
        this.isLoading = false
        return
      }

      this.saveLineItemsData()
    },
    async saveLineItemsData() {
      let errors = []

      for(const lineItem of this.lineItemChanges) {
        const lineItemData = this.splitLineItemChanges(lineItem.lineItems)
        const lineItemPayload = {
          DocumentId: lineItem.DocumentId,
          ...lineItemData,
        }
        const result = await this.$store.dispatch('document/saveDocumentLineItems2', lineItemPayload)
        if (result?.ResultStatus !== 1) {
          errors.push(result?.ResultMessage)
        }
      }

      if (errors.length > 0) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errors.join(', ')
        this.showSnackbar = true
        this.snackbarImage = false
      }

      this.$store.dispatch('document/setLineItemChanges', {})
      this.isDirty = false
      this.$store.dispatch('document/setLineItemChanges', {})
      this.snackbarTitle = 'SUCCESS'
      this.snackbarText = 'Line Item Data Updated!'
      this.showSnackbar = true
      this.snackbarImage = true

      this.isLoading = false
    },
    splitLineItemChanges(changes) {
      let values = {}
      let newCells = []
      Object.keys(changes).forEach(key => {
        if (key.includes('NF-')) {
          const tempField = key.replace('NF-', '').split('-')
          const [tableId, row, col] = tempField
          const newCell = {
            TableId: tableId,
            row,
            col,
            page: 1,
            Value: changes[key],
          }
          newCells.push(newCell)
        } else {
          values[key] = changes[key]
        }
      })
      return { Values: values, NewCells: newCells }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    updateFieldValues(data) {
      const documentIndex = this.documentIDs.indexOf(data.DocumentId)
      this.fieldValues[documentIndex] = data.newFields
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  main {
    width: 100%;
    height: calc(100vh - 178px);
  }

  .header-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-65;
    height: unset !important;

    ::v-deep .vasion-button {
      background-color: $grey-65 !important;
    }
 
    ::v-deep .md-button-content {
      text-transform: none !important;
    }

    .align-text-and-icon {
      padding-right: 10px;
    }

    .back-button-text {
      color: black !important;
      font-weight: 600;
      display: block;
      float: right;
      padding-top: 8px;
    }

    .header-left {
      font-size: 10px;
      margin-bottom: 6px;
    }

    .header-right {
      padding-right: 10px;

      .filter-title {
        display: block;
        float: left;
        margin-top: 14px;
        font-family: Archivo;
        font-size: 12px;
      }

      ::v-deep .clear-button .md-button {
        .md-button-content {
          font-size: 12px !important;
          color: $black;      
        }
      }

      ::v-deep .clear-button .md-button:hover, .md-button:focus {
         background-color: transparent !important;      
 
         .md-button-content {
            color: $black !important;      
         }
      }
    }
  }

  .second-header-div {
    display: flex;
    padding-top: 10px;
    background-color: $grey-35;
    height: unset !important;
 
    .second-header-left {
      flex-grow: 0;
      font-size: 10px;
      padding-left: 10px;
      margin-right: 20px;
    }

    .second-header-right {
      flex-grow: 1;
      padding-right: 20px;

      ::v-deep .multi-droplist-container {
        background-color: $white !important;
        height: 42px;
        max-height: 42px;
        overflow: auto;   
        
        .droplist-tab {
          padding: 0px 5px;
        }
      }
    }
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3px;
    padding-right: 100px;
  }

  #body-area {
    display: flex;
    background: $grey-35;
    height: calc(100vh - 215px);

    .document-display {
      position: relative;
      margin: 10px;
      overflow: hidden;
      height: calc(100vh - 225px);
      text-align: center;
      background: $white;
      width: 33%;
      max-width: 50%;
      border-right: solid 1px $divider-light-grey;

      .preview-header {
        margin-top: 12px;

        .preview-button {
          display: block;
          float: left;
        }

        .zoom-tool {
          display: block;
          float: right;
          margin-right: 10px;
        }

        .align-text-and-icon {
           padding-right: 10px;
           padding-bottom: 10px;
        }

        .preview-button-text {
          text-transform: none !important;
          color: black !important;
          font-weight: 600;
          display: block;
          float: right;
          padding-top: 6px;
          font-size: 18px;
       }        
      }

      .document-image {
        margin-top: 60px;
        height: calc(100vh - 326px);
        overflow: auto;
      }

      .preview-footer {
        width: 260px;
        position: absolute;
        left: 50%;
        bottom: 15px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
      }
    }

    .field-validation-side {
      margin: 10px 20px 0px 10px;
      width: 66%;
      max-width: 100%;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      padding: 0px 12px;
      
      .field-validation-title {
        font-size: 20px;
        font-family: Archivo;
      }

      .editable-table {
        overflow-x: scroll;
        height: calc(100vh - 386px);
      }
    }

    .full-width {
      width: 100% !important;
    }

    .pad-bottom {
      padding-bottom: 20px;
    }
  }
</style>
